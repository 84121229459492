<template>
  <div class="px-30">
    <slot />
  </div>
</template>

<script lang="ts" setup>
useHead({
  htmlAttrs: {
    class: '!bg-white print-view',
  },
  bodyAttrs: {
    class: '!bg-white',
  },
})
</script>

<style lang="postcss">
html.print-view {
  print-color-adjust: exact;
  hyphens: auto;
  .max-w-prose {
    max-width: none !important;
  }

  #nuxt-devtools-container,
  #vue-inspector-container {
    @apply !hidden;
  }
}
</style>
